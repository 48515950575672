import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import ButtonDefault from 'components/elements/Button/ButtonDefault'

// Third Party
import styled from 'styled-components'

const CtaWrapper = styled.div`
  background: ${(props) => props.theme.color.face.secondary};

  h3 {
    font-size: ${(props) => props.theme.font.size[32]};
  }
`

const Content = styled(ParseContent)`
  h3 {
    line-height: 35px;
  }
`

const StyledImage = styled(Plaatjie)`
  width: 100%;
  max-width: 600px;
  & img {
    object-fit: cover !important;
  }
`

interface CtaWithImageProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_CtaWithImage
}

const CtaWithImage: React.FC<CtaWithImageProps> = ({ fields }) => (
  <CtaWrapper className="py-0">
    <div className="container py-4">
      <div className="row">
        <div className="col-lg-6 col-12 d-flex align-items-center">
          <div>
            <Content content={fields.title} />
            <ButtonDefault
              to={fields?.link?.url || '/404'}
              className="my-5 my-lg-3"
            >
              {fields?.link?.title}
            </ButtonDefault>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <StyledImage image={fields?.image} alt="" />
        </div>
      </div>
    </div>
  </CtaWrapper>
)

export default CtaWithImage
